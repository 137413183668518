import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				STARLIGHT REAL ESTATE
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Starlight Real Estate, де ваші мрії про ідеальну оселю втілюються в реальність"} />
			<meta property={"og:title"} content={"STARLIGHT REAL ESTATE"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Starlight Real Estate, де ваші мрії про ідеальну оселю втілюються в реальність"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Starlight Real Estate
			</Override>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,--color-primary 100%),url(https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-4.jpg?v=2024-04-26T14:30:50.816Z)">
			<Override slot="link" display="none" />
			<Override slot="text">
				Наші послуги
			</Override>
			<Override slot="text1" color="--light">
				У Starlight Real Estate ми виходимо за рамки базових послуг і надаємо широкий спектр послуг, які допоможуть вам у пошуку ідеального житла. Ми прагнемо, щоб кожен аспект вашого досвіду в сфері нерухомості був охоплений, від початкової консультації до фінального рукостискання.
			</Override>
		</Components.Hero>
		<Section display="flex" flex-direction="row">
			<Section padding="50px 0 50px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
				<Text
					margin="0px 0px 90px 0px"
					font="--headline2"
					color="--darkL1"
					border-color="--color-dark"
					text-align="center"
					lg-margin="0px 0px 50px 0px"
				>
					Пошук нерухомості
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					md-grid-template-columns="1fr"
					md-grid-template-rows="auto"
					md-grid-gap={0}
					lg-padding="0px 0 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								1
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Пошук за вашими критеріями
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Ми використовуємо передові інструменти для фільтрації нерухомості за вашими конкретними уподобаннями.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								2
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Ексклюзивні пропозиції
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Отримайте доступ до нерухомості, яка не представлена на загальному ринку.
							</Text>
						</Box>
					</Box>
				</Box>
			</Section>
			<Section
				padding="50px 0 50px 0"
				sm-padding="60px 0 60px 0"
				box-shadow="--m"
				background="--color-darkL2"
				md-padding="45px 0 45px 0"
				quarkly-title="Advantages/Features-23"
			>
				<Override slot="SectionContent" max-width="1220px" align-items="center" sm-min-width="280px" />
				<Box
					width="100%"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					margin="0px 0px 64px 0px"
					display="flex"
					lg-width="100%"
					align-items="center"
					lg-margin="0px 0px 48px 0px"
					md-margin="0px 0px 24px 0px"
					font="--headline3"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 28px/1.2 --fontFamily-sans"
						text-align="center"
						md-font="--headline3"
					>
						Фінансові консультації
					</Text>
				</Box>
				<Box
					display="grid"
					lg-flex-wrap="wrap"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					lg-grid-gap="16px"
					md-grid-template-columns="1fr"
					max-width="1000px"
				>
					<Box
						display="flex"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						flex-direction="column"
						justify-content="flex-start"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text font="--lead" margin="0px 0px 12px 0px" color="--light" text-align="center">
							<Strong>
								Допомога у кредитуванні
							</Strong>
						</Text>
						<Text
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							font="--base"
							text-align="center"
						>
							Партнерство з авторитетними фінансовими установами для отримання найкращих варіантів фінансування.
						</Text>
					</Box>
					<Box
						justify-content="flex-start"
						flex-direction="column"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						display="flex"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text color="--light" font="--lead" margin="0px 0px 12px 0px" text-align="center">
							<Strong>
								Інвестиційні поради
							</Strong>
						</Text>
						<Text
							font="--base"
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="center"
						>
							Стратегічні інсайти для максимального потенціалу вашої інвестиції в нерухомість.
						</Text>
					</Box>
				</Box>
			</Section>
		</Section>
		<Section display="flex" flex-direction="row">
			<Section padding="\\n\\n50px 0 50px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
				<Text
					margin="0px 0px 90px 0px"
					font="--headline2"
					color="--darkL1"
					border-color="--color-dark"
					text-align="center"
					lg-margin="0px 0px 50px 0px"
				>
					Юридичні послуги
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					md-grid-template-columns="1fr"
					md-grid-template-rows="auto"
					md-grid-gap={0}
					lg-padding="0px 0 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								1
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Огляд контрактів
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Професійна юридична консультація для забезпечення ясності та безпеки ваших контрактів.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								2
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Передача власності
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Допомога з усіма документами та процедурами, пов'язаними з передачею права власності на нерухомість.
							</Text>
						</Box>
					</Box>
				</Box>
			</Section>
			<Section
				padding="50px 0 50px 0"
				sm-padding="60px 0 60px 0"
				box-shadow="--m"
				background="--color-darkL2"
				md-padding="45px 0 45px 0"
				quarkly-title="Advantages/Features-23"
			>
				<Override slot="SectionContent" max-width="1220px" align-items="center" sm-min-width="280px" />
				<Box
					width="100%"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					margin="0px 0px 64px 0px"
					display="flex"
					lg-width="100%"
					align-items="center"
					lg-margin="0px 0px 48px 0px"
					md-margin="0px 0px 24px 0px"
					font="--headline3"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 28px/1.2 --fontFamily-sans"
						text-align="center"
						md-font="--headline3"
					>
						Підтримка після продажу
					</Text>
				</Box>
				<Box
					display="grid"
					lg-flex-wrap="wrap"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					lg-grid-gap="16px"
					md-grid-template-columns="1fr"
					max-width="1000px"
				>
					<Box
						display="flex"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						flex-direction="column"
						justify-content="flex-start"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text font="--lead" margin="0px 0px 12px 0px" color="--light" text-align="center">
							<Strong>
								Управління нерухомістю
							</Strong>
						</Text>
						<Text
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							font="--base"
							text-align="center"
						>
							Комплексні послуги з управління, щоб підтримувати ваш дім у відмінному стані.
						</Text>
					</Box>
					<Box
						justify-content="flex-start"
						flex-direction="column"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						display="flex"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text color="--light" font="--lead" margin="0px 0px 12px 0px" text-align="center">
							<Strong>
								Обслуговування клієнтів
							</Strong>
						</Text>
						<Text
							font="--base"
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="center"
						>
							Постійна підтримка, щоб ви залишалися задоволені своєю покупкою.
						</Text>
					</Box>
				</Box>
			</Section>
		</Section>
		<Section display="flex" flex-direction="row">
			<Section padding="50px 0 50px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
				<Text
					margin="0px 0px 90px 0px"
					font="--headline2"
					color="--darkL1"
					border-color="--color-dark"
					text-align="center"
					lg-margin="0px 0px 50px 0px"
				>
					Аналіз ринку
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					md-grid-template-columns="1fr"
					md-grid-template-rows="auto"
					md-grid-gap={0}
					lg-padding="0px 0 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								1
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Тенденції ринку
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Будьте в курсі останніх ринкових тенденцій, щоб приймати обґрунтовані рішення.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						padding="0px 50px 30px 0"
						sm-padding="0px 0 34px 0"
						lg-padding="0px 25px 0 0"
						md-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 20px 0px"
					>
						<Box
							min-width="40px"
							min-height="40px"
							background="--color-darkL1"
							display="flex"
							align-items="center"
							justify-content="center"
							width="40px"
							height="40px"
							border-radius="50%"
							margin="0px 0px 30px 0px"
							color="--darkL2"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
								2
							</Text>
						</Box>
						<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
							<Text
								margin="0 0 15px 25px"
								border-color="--color-light"
								color="--darkL1"
								font="normal 500 22px/1.2 --fontFamily-sans"
								text-align="left"
							>
								Переговори щодо ціни
							</Text>
							<Text
								margin="0 0 0 25px"
								font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
								color="#65696d"
								text-align="left"
								border-color="#7a7c7f"
							>
								Експертні переговори для отримання найкращої угоди.
							</Text>
						</Box>
					</Box>
				</Box>
			</Section>
			<Section
				padding="50px 0 50px 0"
				sm-padding="60px 0 60px 0"
				box-shadow="--m"
				background="--color-darkL2"
				md-padding="45px 0 45px 0"
				quarkly-title="Advantages/Features-23"
			>
				<Override slot="SectionContent" max-width="1220px" align-items="center" sm-min-width="280px" />
				<Box
					width="100%"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					margin="0px 0px 64px 0px"
					display="flex"
					lg-width="100%"
					align-items="center"
					lg-margin="0px 0px 48px 0px"
					md-margin="0px 0px 24px 0px"
					font="--headline3"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 28px/1.2 --fontFamily-sans"
						text-align="center"
						md-font="--headline3"
					>
						Послуги з переселення
					</Text>
				</Box>
				<Box
					display="grid"
					lg-flex-wrap="wrap"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					lg-grid-gap="16px"
					md-grid-template-columns="1fr"
					max-width="1000px"
				>
					<Box
						display="flex"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						flex-direction="column"
						justify-content="flex-start"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text font="--lead" margin="0px 0px 12px 0px" color="--light" text-align="center">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Координація переїзду
							</Strong>
						</Text>
						<Text
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							font="--base"
							text-align="center"
						>
							Ми координуємо послуги з переїзду, щоб зробити ваш перехід безпроблемним.
						</Text>
					</Box>
					<Box
						justify-content="flex-start"
						flex-direction="column"
						padding="46px 46px 47px 46px"
						background="--color-darkL2"
						display="flex"
						box-shadow="--l"
						align-items="center"
						lg-padding="24px 24px 24px 24px"
						border-radius="6px"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					>
						<Text color="--light" font="--lead" margin="0px 0px 12px 0px" text-align="center">
							<Strong>
								Допомога з облаштуванням
							</Strong>
						</Text>
						<Text
							font="--base"
							flex="1 0 auto"
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="center"
						>
							Допомога у знаходженні місцевих зручностей та послуг, щоб ви могли швидко облаштуватися у вашому новому домі.
						</Text>
					</Box>
				</Box>
			</Section>
		</Section>
		<Components.Contacts>
			<Override slot="box2" />
			<Override slot="text" />
			<Override slot="text2" />
		</Components.Contacts>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});